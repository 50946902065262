import { ConfigStateService } from '@abp/ng.core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberNoGrouping',
})
export class NumberNoGroupingPipe implements PipeTransform {
  locale: string;

  constructor(private configStateService: ConfigStateService) {
    const localize = this.configStateService.getOne('localization');

    this.locale = localize.currentCulture.cultureName;
  }

  transform(value: number, digitsInfo = '1.0-2', locale = this.locale): string {
    const [minInt, minFraction, maxFraction] = digitsInfo
      .split('.')
      .map(part => part.split('-').map(Number))
      .flat();

    return new Intl.NumberFormat(locale, {
      minimumIntegerDigits: minInt,
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
      useGrouping: false,
    }).format(value || 0);
  }
}
