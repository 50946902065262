export const PRODUCT_VERSION_APP_NAME = 'livechat-server';
export const NOT_AVAILABLE = 'N/A';

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0; //eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8; //eslint-disable-line no-bitwise
    return v.toString(16);
  });
};

export const isDateRangeValid = (fromDate: Date, toDate: Date, maxMonthsAllowed: number): boolean => {
  const adjustedDate = new Date(toDate);

  if (toDate.getMonth() < maxMonthsAllowed) {
    adjustedDate.setFullYear(toDate.getFullYear() - 1);
    adjustedDate.setMonth(toDate.getMonth() + (12 - maxMonthsAllowed));
  } else {
    adjustedDate.setMonth(toDate.getMonth() - maxMonthsAllowed);
  }

  return adjustedDate < fromDate;
};
