import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { DateFilterModel } from '../../shared.models';

@Component({
  selector: 'lc-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() maxYearsBack = 1;
  @Output() dateFilterChanged = new EventEmitter<DateFilterModel>();

  maxDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  minDate: NgbDate;
  dateFilter: DateFilterModel;

  constructor(private calendar: NgbCalendar) {}

  ngOnInit() {
    this.minDate = new NgbDate(this.calendar.getToday().year - this.maxYearsBack, 1, 1);
    this.maxDate = this.calendar.getToday();

    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', -1);
    this.toDate = this.calendar.getToday();

    this.setDateFilter();
  }

  setDateFilter() {
    this.dateFilter = {
      from: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
      to: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59, 999),
    };

    this.dateFilterChanged.emit(this.dateFilter);
  }
}
