import { RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      iconClass: 'far fa-table',
      name: '::Menu:AgentDashboard',
      order: 2,
      parentName: '::Menu:Dashboards',
      requiredPolicy: 'LiveChat.AgentDashboard',
    },
    {
      path: 'agent-dashboard/agent-reporting',
      name: '::Menu:AgentReporting',
      order: 201,
      parentName: '::Menu:AgentDashboard',
      requiredPolicy: 'LiveChat.AgentDashboard',
    },
    {
      path: 'agent-dashboard/agent-state-durations',
      name: '::Menu:AgentStateDurations',
      order: 202,
      parentName: '::Menu:AgentDashboard',
      requiredPolicy: 'LiveChat.AgentDashboard',
    },
    {
      path: 'agent-dashboard/agent-state-intervals',
      name: '::Menu:AgentStateIntervals',
      order: 203,
      parentName: '::Menu:AgentDashboard',
      requiredPolicy: 'LiveChat.AgentDashboard',
    },
  ]);
};

export const AGENT_DASHBOARD_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
