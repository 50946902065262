<div class="row">
  <div class="col">
    <div class="form-group">
      <div class="input-group">
        <label class="input-group-text">{{ '::StartDate' | abpLocalization }}</label>
        <input
          ngbDatepicker
          #fromDatePicker="ngbDatepicker"
          class="form-control"
          name="fromDatePicker"
          readonly
          [minDate]="minDate"
          [maxDate]="toDate"
          [(ngModel)]="fromDate"
          (click)="fromDatePicker.toggle()"
          (dateSelect)="setDateFilter()"
        />
      </div>
    </div>
  </div>

  <div class="col">
    <div class="form-group">
      <div class="input-group">
        <label class="input-group-text">{{ '::EndDate' | abpLocalization }}</label>
        <input
          ngbDatepicker
          #toDatePicker="ngbDatepicker"
          class="form-control"
          name="toDatePicker"
          readonly
          [minDate]="fromDate"
          [maxDate]="maxDate"
          [(ngModel)]="toDate"
          (click)="toDatePicker.toggle()"
          (dateSelect)="setDateFilter()"
        />
      </div>
    </div>
  </div>
</div>
