import { CoreModule } from '@abp/ng.core';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { Badger } from './badger';
import { FileDownloadHelperService } from './services/file-download-helper.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { SestekCommonComponentsModule } from '@sestek/common/components';
import { SestekCommonPipesModule } from '@sestek/common/pipes';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { NumberNoGroupingPipe } from './pipes/number-no-grouping.pipe';

export const favIconBadger = new Badger({});

@NgModule({
  declarations: [DateFilterComponent, NumberNoGroupingPipe],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgSelectModule,
    SestekCommonComponentsModule,
    SestekCommonPipesModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgSelectModule,
    SestekCommonComponentsModule,
    SestekCommonPipesModule,
    DateFilterComponent,
    NumberNoGroupingPipe,
  ],
  providers: [FileDownloadHelperService],
})
export class SharedModule {}
